<template>
  <div class="custom-editable-input">
    <div v-if="editing" class="editing-value">
      <input
        type="number"
        step="0.01"
        v-model="updateV"
        @input="inputValue"
        @change="inputValue"
        class="input"
      />
    </div>
    <div v-else @dblclick="editing = true" class="show-value">
      {{ value
      }}<font-awesome-icon class="show-view-pencil ml-2" icon="pencil-alt" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    field: {
      type: String
    },
    data: {
      required: false
    }
  },
  data() {
    return {
      editing: false,
      updateV: this.value
    };
  },
  methods: {
    inputValue() {
      this.$emit('handleChangeValueConfig', {
        value: this.updateV,
        field: this.field,
        index: this.data.index
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-editable-input {
  .editing-value {
    .input {
      width: 72px;
      border: none;
      text-align: center;
      &:focus-visible {
        border: none !important;
      }
    }
  }

  .show-value {
    .show-view-pencil {
      color: #9e9e9e;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
</style>
